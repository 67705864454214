import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AWS from "aws-sdk";
import {ManagedUpload} from "aws-sdk/clients/s3";
import {useFormikContext} from "formik";
import {Field} from "formik";
import * as url from "node:url";

import {IStore} from "../../app/reducers/hybrid_reducer";

const S3_BUCKET = "s3-zakupauto-service";
const REGION = "eu-central-1";

AWS.config.update({
    accessKeyId: "AKIAS4ITX67NWQRHOWLU",
    secretAccessKey: "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"
});

const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION
});

const EditUploadImageToS3 = () => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const {values, setFieldValue, submitForm} = useFormikContext<{filekeys: string}>();
    const [fileKeys, setFileKeys] = useState<string[]>(values.filekeys?.split("|"));
    const userData = useSelector((store: IStore) => store.dashboard.userData);
    const [deletedFile, setDeletedFile] = useState<string | null>(null); // Stan do śledzenia usuniętego pliku
    // Pobierz obrazy z S3 na podstawie fileKeys
    useEffect(() => {
        if (values.filekeys) {
            const fileKeysArray = values.filekeys.split("|");
            const urls = fileKeysArray.filter((key) => {
                return key !== "" ? `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}` : null;
            });
            setImageUrls(urls);
        }
    }, []);

    const uploadFile = (file: File) => {
        const uniqueKey = `${userData.data.user_id}_${file.name}`;

        const params = {
            Body: file,
            Bucket: S3_BUCKET,
            Key: uniqueKey
        };

        myBucket
            .upload(params, {
                queueSize: 1,
                tags: [
                    {Key: "Category", Value: "gallery"},
                    {Key: "userID", Value: `${userData.data.user_id}`}
                ]
            })
            .on("httpUploadProgress", (evt) => {
                const progress = Math.round((evt.loaded / evt.total) * 100);
                setUploadProgress((prevProgress) => [...prevProgress, progress]);
            })
            .send((err: Error, data: ManagedUpload.SendData) => {
                if (err) {
                    console.error("Upload error:", err);
                    return;
                }

                setImageUrls((prevUrls) => [...prevUrls, data.Key]);

                setFileKeys((prevKeys) => {
                    const updatedKeys = [...prevKeys, data.Key];
                    setFieldValue("filekeys", updatedKeys.join("|"));

                    return updatedKeys;
                });
            });
    };

    const handleRemoveImage = (key: string) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: key
        };

        myBucket.deleteObject(params, (err, data) => {
            if (err) {
                console.error("Error deleting file from S3:", err);
                return;
            }

            setSelectedFiles((prevFiles) => prevFiles.filter((file) => `${userData.data.user_id}_${file.name}` !== key));
            setImageUrls((prevUrls) => {
                const updatedUrls = prevUrls.filter((url) => url !== key);
                return updatedUrls;
            });

            setFileKeys((prevKeys) => {
                const updatedKeys = prevKeys.filter((fileKey) => fileKey !== key);
                return updatedKeys;
            });
            setDeletedFile(key);
        });
    };

    useEffect(() => {
        // Gdy fileKeys się zmienia, zaktualizuj filekeys w Formiku
        setFieldValue("filekeys", fileKeys?.join("|")).then(() => {
            if (deletedFile) {
                submitForm();
            }
        });
    }, [fileKeys, setFieldValue, deletedFile, submitForm]);

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileArray = Array.from(files);
            setSelectedFiles(fileArray);
            fileArray.forEach((file) => uploadFile(file));
        }
    };

    return (
        <div>
            <input type="file" multiple onChange={handleFileInput} />
            <div style={{display: "flex", flexWrap: "wrap", marginTop: "20px"}}>
                {imageUrls.map((url, index) => {
                    const imageKey = url.split("/").pop(); // Extract the file key from the URL
                    if (!imageKey) {
                        return;
                    }
                    const src = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${imageKey}`;

                    return (
                        <div key={url} style={{margin: "10px", textAlign: "center"}}>
                            <img
                                src={src}
                                alt="Preview"
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    marginBottom: "10px"
                                }}
                            />
                            {uploadProgress[index] > 0 && (
                                <progress
                                    value={uploadProgress[index]}
                                    max={100}
                                    style={{
                                        width: "100%",
                                        height: "10px",
                                        borderRadius: "5px",
                                        backgroundColor: "#f3f3f3",
                                        appearance: "none"
                                    }}
                                />
                            )}
                            <button
                                type="button"
                                onClick={() => handleRemoveImage(imageKey)}
                                style={{
                                    display: "block",
                                    marginTop: "5px",
                                    backgroundColor: "red",
                                    color: "white"
                                }}
                            >
                                Usuń
                            </button>
                        </div>
                    );
                })}
            </div>
            <Field type="hidden" name="filekeys" value={values.filekeys} />
        </div>
    );
};

export default EditUploadImageToS3;
