import React, {useState} from "react";
import {useSelector} from "react-redux";
import AWS from "aws-sdk";
import {ManagedUpload} from "aws-sdk/clients/s3";
import {useFormikContext} from "formik";

import {IStore} from "../../app/reducers/hybrid_reducer";

const S3_BUCKET = "s3-zakupauto-service";
const REGION = "eu-central-1";

AWS.config.update({
    accessKeyId: "AKIAS4ITX67NWQRHOWLU",
    secretAccessKey: "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"
});

const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION
});

const UploadImageToS3WithNativeSdk = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [filePreviews, setFilePreviews] = useState<string[]>([]); // Lista podglądów zdjęć
    const [uploadProgress, setUploadProgress] = useState<number[]>([]); // Postęp dla każdego pliku
    const [fileKeys, setFileKeys] = useState<string[]>([]); // Lista kluczy plików na S3
    const userData = useSelector((store: IStore) => store.dashboard.userData);
    const {values, setFieldValue} = useFormikContext(); // Dostęp do Formika
    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileArray = Array.from(files);
            setSelectedFiles(fileArray);

            const previews = fileArray.map((file) => {
                const reader = new FileReader();
                return new Promise<string>((resolve) => {
                    reader.onloadend = () => resolve(reader.result as string);
                    reader.readAsDataURL(file); // Tworzy URL base64 dla obrazu
                });
            });

            // Po uzyskaniu wszystkich podglądów ustawiamy je w stanie
            Promise.all(previews).then((previews) => {
                setFilePreviews(previews);
            });

            // Zresetowanie postępu ładowania przed rozpoczęciem nowego ładowania
            setUploadProgress(new Array(fileArray.length).fill(0));

            fileArray.forEach((file, index) => uploadFile(file, index)); // Automatyczne przesyłanie plików
        }
    };

    const uploadFile = (file: File, index: number) => {
        const uniqueKey = `${userData.data.user_id}_${file.name}`;
        const params = {
            Body: file,
            Bucket: S3_BUCKET,
            Key: uniqueKey
        };

        myBucket
            .upload(params, {
                queueSize: 1,
                tags: [
                    {Key: "Category", Value: "gallery"},
                    {Key: "userID", Value: `${userData.data.user_id}`}
                ]
            })
            .on("httpUploadProgress", (evt) => {
                // Calculate the progress as a percentage
                const progress = Math.round((evt.loaded / evt.total) * 100);

                // Zaktualizowanie postępu dla konkretnego pliku
                setUploadProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[index] = progress;
                    return newProgress;
                });
            })
            .send((err: Error, data: ManagedUpload.SendData) => {
                if (err) {
                    console.log(err);
                    return;
                }

                // Po zakończeniu ładowania, uzyskujemy URL pliku
                const fileUrl = `https://${S3_BUCKET}.s3.amazonaws.com/${params.Key}`;

                // Dodajemy klucz pliku do listy kluczy
                setFileKeys((prevKeys) => [...prevKeys, data.Key]);
                setFieldValue("fileKeys", [...fileKeys, data.Key]);
            });
    };

    // Funkcja do usuwania zdjęcia po kluczu
    const handleRemoveImage = (key: string) => {
        // Usuwanie pliku z S3
        const params = {
            Bucket: S3_BUCKET,
            Key: key
        };

        myBucket.deleteObject(params, (err, data) => {
            if (err) {
                //add handle error fo not deleted files
                console.error("Error deleting file from S3:", err);
                return;
            }

            // Usuwanie pliku z lokalnego stanu
            setSelectedFiles((prevFiles) => prevFiles.filter((file) => `${userData.data.user_id}_${file.name}` !== key));
            setFilePreviews((prevPreviews) => prevPreviews.filter((_, index) => `${userData.data.user_id}_${selectedFiles[index].name}` !== key));
            setUploadProgress((prevProgress) => prevProgress.filter((_, index) => `${userData.data.user_id}_${selectedFiles[index].name}` !== key));
            setFileKeys((prevKeys) => prevKeys.filter((fileKey) => fileKey !== key));
            setFieldValue(
                "fileKeys",
                fileKeys.filter((fileKey) => fileKey !== key)
            ); // Zaktualizowanie fileKeys w Formik
        });
    };

    return (
        <div>
            <div>
                <input type="file" multiple onChange={handleFileInput} />
            </div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {selectedFiles.map((image, index) => {
                    const imageKey = `${userData.data.user_id}_${image.name}`;
                    return (
                        <div key={image.name} style={{margin: "10px"}}>
                            <div>
                                <img
                                    src={filePreviews[index]} // Wyświetlanie podglądu zdjęcia
                                    alt="Preview"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        marginBottom: "10px"
                                    }}
                                />
                                {uploadProgress[index] > 0 && (
                                    <div style={{width: "100%", marginTop: "5px"}}>
                                        <progress
                                            value={uploadProgress[index]}
                                            max={100}
                                            style={{
                                                width: "100%",
                                                height: "10px",
                                                borderRadius: "5px",
                                                backgroundColor: "#f3f3f3",
                                                appearance: "none"
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            <button
                                type="button"
                                onClick={() => handleRemoveImage(imageKey)} // Usuwanie zdjęcia po Key
                                style={{
                                    display: "block",
                                    marginTop: "5px",
                                    backgroundColor: "red",
                                    color: "white"
                                }}
                            >
                                Usuń
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UploadImageToS3WithNativeSdk;
