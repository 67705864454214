import {combineReducers, Reducer} from "redux";

import {fetchCarListTypes} from "../../app/routes/data_fetcher/app_path_data_fetcher";
import {reduceResponse, reduceResponseField} from "../../app/utils/request_response_utils/factories/reduce_response";
import {IGalleryObjType} from "../../dashboard/add_car/AddCar";
import {IOfferListQuery} from "../../offer/list/reducers/offer_list_reducer";
import {reduceLatestQuery} from "../../offer/list/reducers/reduce_latest_query";
import {customMetaActionTypes, CustomMetaData} from "../actions/fetch_custom_meta_data";
import {
    IMetaDataOptions,
    IUpdateMetaDataAction,
    IUpdateMetaTitleDescriptionAction,
    UPDATE_META_DATA,
    UPDATE_META_TITLE_DESCRIPTION
} from "../actions/update_meta_data";

export interface IMetaDataStore {
    metaData: IStandardMetaData;
    customMetaData?: CustomMetaData;
}
export interface IStandardMetaData extends IMetaDataOptions {
    title: string;
    description: string;
}

export const defaultMetaData = {
    description: "Samochody na sprzedaż cała Polska",
    robots: "index, follow",
    title: "Samochody na sprzedaż",
    lang: "pl"
};

export interface IStore {
    metaData: IMetaDataStore;
}

export const standardMetaDataReducer = (
    state: IStandardMetaData = defaultMetaData,
    action: IUpdateMetaDataAction | IUpdateMetaTitleDescriptionAction
): IStandardMetaData => {
    switch (action.type) {
        case UPDATE_META_TITLE_DESCRIPTION:
            return {
                ...state,
                ...(action.title ? {title: action.title} : {}),
                ...(action.description ? {description: action.description} : {})
            };
        case UPDATE_META_DATA:
            return {
                title: action.title,
                description: action.description,
                canonical: action.canonical,
                relPrev: action.relPrev,
                relNext: action.relNext,
                robots: action.robots,
                image: action.image,
                keywords: action.keywords,
                author: action.author,
                url: action.url,
                hideSuffix: action.hideSuffix,
                lang: action.lang,
                maxImagePreview: action.maxImagePreview
            };
        default:
            return state;
    }
};

const metaDataReducerObj: Record<keyof IMetaDataStore, Reducer<any, any>> = {
    metaData: standardMetaDataReducer,
    customMetaData: reduceResponse(customMetaActionTypes)
};

export const metaDataReducer = combineReducers(metaDataReducerObj);

export interface ICarListOfferResponse {
    id: number;
    title: string;
    content: string;
    slug: string;
    gallery?: any;
    acf: {
        make: string;
        model: string;
        year: string;
        millage: string;
        engine: string;
        price: string;
        gearbox: string;
        gallery?: any;
        filekeys: string;
        //  gallery?: IGalleryObjType[];
    };
    data_back?: any;
}
