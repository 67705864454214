import {combineReducers} from "redux";

import {reduceResponse} from "../../app/utils/request_response_utils/factories/reduce_response";
import {ICarListOfferResponse} from "../../meta_data/reducers/meta_data_reducer";
import {IUserData, setDashboardUserOffersTypes, setUserToStoreTypes} from "../Dashboard";

export interface IDashBoardStore {
    userOfferList: ICarListOfferResponse[];
    userData: IUserData;
}

export const dashboardReducer = combineReducers({
    userOfferList: reduceResponse(setDashboardUserOffersTypes),
    userData: reduceResponse(setUserToStoreTypes)
});
