import {useState} from "react";
import AWS from "aws-sdk";
import {Dispatch} from "redux";

import {IFullCarDetailParams} from "../../../../../../packages/utils/cars_routes";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {ISearchResultRouteParams} from "../../list/actions/redirect_constraints";
import {fetchCarTypes} from "./fetch_cars_details_at_route";

export interface ICarDetailResponse {
    data: {
        id: number;
        title: string;
        content: string;
        slug: string;
        acf: {
            make: string;
            model: string;
            year: string;
            millage: string;
            engine: string;
            price: string;
            gallery?: any;
            gearbox: string;
            engine_size: string;
            horse_power: string;
            city: string;
            wojew: string;
            first_registation_date: string;
            sellers_name: string;
            phone_number: string;
            body_type: string;
            damaged: string; //'tak/nie'
            imported: string;
            fileKeys?: string;
        };
        details_basic: {
            version: string;
            generation: string;
            doors_counts: string;
            spots_counts: string;
            car_color: string;
            vin: string;
        };
        multimedia_options: {
            multimedia: string[];
            comfort_and_convenience: string[];
            appearance_and_materials: string[];
            safety: string[];
            additional_features: string[];
        };

        data_back?: any;
    };
}

export interface MultimediaOptions {
    multimedia: string[];
    comfort_and_convenience: string[];
    appearance_and_materials: string[];
    safety: string[];
    additional_features: string[];
}
const S3_BUCKET = "s3-zakupauto-service";
const REGION = "eu-central-1";

AWS.config.update({
    accessKeyId: "AKIAS4ITX67NWQRHOWLU",
    secretAccessKey: "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"
});

const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION
});

export const fetchCarGalleryTypes = createRequestActionTypes({view: "Offer", type: "GET", name: "CarDetailFetchGallery"});

export const fetchCarsDetailsAWSGallery =
    (services: Partial<IServices>, route: IRouteState<IFullCarDetailParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const fileKeys = getState().offer.car.data.acf.filekeys?.split("|");
        dispatch({type: fetchCarGalleryTypes.start});
        if (fileKeys) {
            const urls = fileKeys.map((key) => `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${key}`);
            dispatch({type: fetchCarGalleryTypes.success, result: {data: {galleryAWS: urls}}});

            return urls;
        }
    };
