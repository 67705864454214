import {combineReducers, Reducer} from "redux";

import {reduceResponse} from "../../../app/utils/request_response_utils/factories/reduce_response";
import {reduceLatestQuery} from "../../../offer/list/reducers/reduce_latest_query";
import {fetchCarDetailsToEditTypes, mappedCarDetailResponse} from "../actions/fetch_car_details_to_edit_at_route";

const editOfferReducerReducer = combineReducers({
    latestQuery: reduceLatestQuery(fetchCarDetailsToEditTypes)
});
export interface IEditOfferStore {
    carEditData: mappedCarDetailResponse;
    editOffer: {
        latestQuery: {offerId: string};
    };
}

const editOfferReducerObj: Record<keyof IEditOfferStore, Reducer<any, any>> = {
    carEditData: reduceResponse(fetchCarDetailsToEditTypes),
    editOffer: editOfferReducerReducer
};

export const editOfferReducer = combineReducers(editOfferReducerObj);
