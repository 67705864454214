import {INotificationsStore, notificationsReducer} from "@rw/notifications";
import {combineReducers, Reducer} from "redux";

import {mappedCarDetailResponse} from "../../dashboard/edit_car/actions/fetch_car_details_to_edit_at_route";
import {editOfferReducer, IEditOfferStore} from "../../dashboard/edit_car/reducers/reducer";
import {dashboardReducer, IDashBoardStore} from "../../dashboard/reducers/dashboard_reducer";
import {IMetaDataStore, metaDataReducer} from "../../meta_data/reducers/meta_data_reducer";
import {fetchCarTypes, ICarDetailResponse} from "../../offer/detail/actions/fetch_cars_details_at_route";
import {fetchCarGalleryTypes, fetchCarsDetailsAWSGallery} from "../../offer/detail/actions/fetch_cars_details_AWS_Gallery";
import {setOfferDetailViewTypes} from "../../offer/list/actions/set_view_mode_for_offer_detail";
import {carListReducer, ICarListStore} from "../../offer/list/reducers/offer_list_reducer";
import {IOfferDetailViewType} from "../../offer/views/OfferListDetailSwitch";
import {IViewTypeStore, viewTypeReducer} from "../../tracking/view_type/view_type_reducer";
import {IAction} from "../interfaces/IAction";
import {createRequestActionTypes, RequestActionTypes} from "../utils/request_response_utils/factories/create_request_action_types";
import {reduceRequestState, RequestState} from "../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../utils/request_response_utils/factories/reduce_response";
import {responseStateReducer, ResponseStateStore} from "../utils/request_response_utils/response_state/response_state_reducer";
import {favouritesReducer, IFavouritesStore} from "./favourites_reducer";
export interface ISetViewTypeAction extends IAction {
    viewType: string;
}

export const fetchOfferLocationDetailTypes = createRequestActionTypes({view: "OfferDetail", type: "GET", name: "locationDetail"});
//TODO przeniesc w inne miejsce
export const reduceViewType =
    (requestTypes: RequestActionTypes) =>
    (state: string | null = null, action: ISetViewTypeAction): string | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.viewType;
            default:
                return state;
        }
    };

export interface IOfferStore {
    offerDetailViewType: IOfferDetailViewType | null;
    offerRequestState: RequestState;
    car: ICarDetailResponse;
    carGallleryAWS: {data: {galleryAWS: string[]}};
}

const offerReducerObj: Record<keyof IOfferStore, Reducer<any, any>> = {
    offerDetailViewType: reduceViewType(setOfferDetailViewTypes),
    car: reduceResponse(fetchCarTypes),
    carGallleryAWS: reduceResponse(fetchCarGalleryTypes),

    offerRequestState: reduceRequestState(fetchCarTypes)
};

export const offerReducer = combineReducers(offerReducerObj);

export interface IStore {
    notifications: INotificationsStore;
    metaData: IMetaDataStore;
    responseState: ResponseStateStore;
    viewType: IViewTypeStore;
    carList: ICarListStore;
    offer: IOfferStore;
    favourites: IFavouritesStore;
    dashboard: IDashBoardStore;
    editOfferData: IEditOfferStore;
}

const hybridReducerObj: Record<keyof IStore, Reducer<any, any>> = {
    metaData: metaDataReducer,
    notifications: notificationsReducer,
    responseState: responseStateReducer,
    viewType: viewTypeReducer,
    carList: carListReducer,
    offer: offerReducer,
    favourites: favouritesReducer,
    dashboard: dashboardReducer,
    editOfferData: editOfferReducer
};

export const hybridReducer = combineReducers(hybridReducerObj);
