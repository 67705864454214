import React from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {IStore} from "../../../../app/reducers/hybrid_reducer";
import {Container} from "../../../../homepage/components/CarBrandsSection";
import {CarBox} from "./CarBox";

const CarGridContainer = styled.section`
    padding: 20px;
    background-color: #f9f9f9;
`;

const Title = styled.h2`
    font-size: 1.5em;
    margin-bottom: 10px;
`;

const Description = styled.p`
    margin-bottom: 20px;
    color: #555;
`;

const grid = (theme: Theme) => css`
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @media (min-width: ${theme.breakpoints.screen_xxs}) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: ${theme.breakpoints.screen_xs}) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: ${theme.breakpoints.screen_md}) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const S3_BUCKET = "s3-zakupauto-service";
const REGION = "eu-central-1";

export const CarGrid: React.FC = () => {
    const cars = useSelector((store: IStore) => store.carList.cars.latest);

    return (
        <CarGridContainer>
            <Container>
                <Title>Najnowsze oferty samochodów</Title>
                <Description>Znajdź idealny samochód dla siebie spośród naszych najnowszych ofert.</Description>
                <div css={grid}>
                    {cars.map((car, index) => {
                        const url = car.acf.gallery ? "" : `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${car.acf.filekeys.split("|")[0]}`;

                        const img = car.acf.gallery ? car.acf.gallery[0].sizes.medium : url;

                        return (
                            <CarBox
                                key={index}
                                brand={car.acf.make}
                                model={car.acf.model}
                                year={Number(car.acf.year)}
                                mileage={Number(car.acf.millage)}
                                price={Number(car.acf.price)}
                                image={img}
                                slug={car.slug}
                            />
                        );
                    })}
                </div>
            </Container>
        </CarGridContainer>
    );
};
