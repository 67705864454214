/** @jsxImportSource @emotion/react */
import React, {useState} from "react";
import {css, Theme} from "@emotion/react";
import {FieldArray} from "formik";

import {safetyOptions} from "../add_car/options/multimedia_options";

const checkboxLabel = (theme: Theme) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #333;

    input[type="checkbox"] {
        accent-color: ${theme.colors.brand_primary};
        width: 18px;
        height: 18px;
    }

    &:hover {
        color: ${theme.colors.brand_primary};
        cursor: pointer;
    }
`;

type MultimediaPartFormProps = {
    name: string;
    values: any;
};

export const SaftyPartForm = ({name, values}: MultimediaPartFormProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSection = () => setIsOpen((prev) => !prev);

    return (
        <div css={containerStyle}>
            <div css={headerStyle} onClick={toggleSection}>
                Bezpieczeństwo
                <span>{isOpen ? "▲" : "▼"}</span>
            </div>
            <div css={contentStyle(isOpen)}>
                <FieldArray name={name}>
                    {({push, remove}: {push: (value: string) => void; remove: (index: number) => void}) => (
                        <div>
                            {safetyOptions.map((option) => (
                                <div key={option.value} css={{marginBottom: "8px"}}>
                                    <label css={checkboxLabel}>
                                        <input
                                            type="checkbox"
                                            name={name}
                                            value={option.value}
                                            checked={values[name]?.includes(option.value)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    push(option.value);
                                                } else {
                                                    remove(values[name]?.indexOf(option.value));
                                                }
                                            }}
                                        />
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </FieldArray>
            </div>
        </div>
    );
};

const containerStyle = css`
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    max-width: 400px;
    background-color: #f9f9f9;
`;

const headerStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    background-color: #f0f0f0;
    border-bottom: 1px solid #e0e0e0;

    &:hover {
        background-color: #e9e9e9;
    }
`;

const contentStyle = (isOpen: boolean) => css`
    overflow: hidden;
    transition:
        max-height 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    max-height: ${isOpen ? "1000px" : "0"};
    padding: ${isOpen ? "16px" : "0 16px"};
    opacity: ${isOpen ? "1" : "0"};
`;
