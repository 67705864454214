import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {debounce} from "@rw/nodash";
import {Editor} from "tinymce";

import {TextLimitCounter} from "./TextLimitCounter";

export interface IEditorProps {
    name: string;
    onChange: (name: string, value: string) => void;
    label?: string;
    errorOnBottom?: boolean;
    required?: boolean;
    error?: string | null;
    maxLength?: number;
    id: string;
    value: string;
}
interface PastePreProcessEvent {
    content: string;
    readonly internal: boolean;
}

export const TextEditor: React.FC<IEditorProps> = (props) => {
    const onChange = (name: string, value: string) => {
        props.onChange(name, value);
    };

    const [parsedValue, setParsedValue] = useState<string>("");
    const [isTinyMceInitialized, setIsTinyMceInitialized] = useState(false);

    useEffect(() => {
        const tinymce = require("tinymce/tinymce");

        require("tinymce/models/dom");

        // Default icons are required for TinyMCE 5.3 or above. Also import custom icons if applicable
        require("tinymce/icons/default");

        // Editor theme (required) - customize the editor appearance by creating a 'skin'
        require("tinymce/themes/silver");

        require("tinymce/skins/ui/oxide/skin.css");

        // Import plugins
        require("tinymce/plugins/lists");
        require("tinymce/");

        //
        tinymce
            .init({
                selector: `textarea#${props.id}`,
                /* All plugins need to be imported and added to the plugins option. */
                plugins: "lists",
                toolbar: "bold italic bullist numlist ",
                skin: false,
                branding: false,
                content_css: false,
                menubar: false,
                paste_block_drop: true,
                valid_elements: "strong,p,ul,li,ol,em,br",
                elementpath: false,
                content_style:
                    "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'); body { font-size: 0.875rem; color: #37474f; font-family: Poppins; line-height: 1.428571429; }",

                setup: (editor: Editor) => {
                    editor.on("Change", (e) => {
                        onChange(props.name, editor.getContent());
                    });
                    editor.on(
                        "keyPress",
                        debounce(() => {
                            setParsedValue(editor.getContent({format: "text"}));
                        }, 500)
                    );
                }
            })
            .then(() => {
                setIsTinyMceInitialized(true);
                if (props.value.trim() !== "") {
                    setParsedValue(tinymce.activeEditor.getContent({format: "text"}));
                }
            });
    }, []);

    return (
        <EditorContainer>
            <textarea id={props.id} name={props.name} value={props.value} required={props.required} onChange={(e) => onChange(props.name, e.target.value)} />
            {isTinyMceInitialized && props.maxLength ? <TextLimitCounter value={parsedValue} maxLength={props.maxLength} /> : null}
        </EditorContainer>
    );
};

const textareaFieldWrapperStyleMobile = {
    height: "unset"
} as React.CSSProperties;

const EditorContainer = styled.div`
    .tox .tox-statusbar {
        border-top: none;
    }
`;
