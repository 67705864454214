import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {jwtDecode} from "jwt-decode";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {getUserOffers, JwtPayloadData, setUserToStoreTypes} from "../Dashboard";

const useAuthorisation = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [user, setUser] = useState<{id: number | null; displayName: string | null}>({id: null, displayName: null});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errMessage, setErrorMessage] = useState<string>("Brak autoryzacji !");
    const dispatch = useDispatch();
    const latestQuery = useSelector((store: IStore) => store.editOfferData.editOffer.latestQuery);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            setIsAuthenticated(false);
            return;
        }
        const validateToken = async () => {
            const url = apiLink.validateToken({})({token: token, offerId: latestQuery.offerId});

            return postRequest({}, url, {token: token, offerId: latestQuery.offerId})
                .then((res: any) => {
                    if (res.code === 200) {
                        const nonce = res.nonce;
                        // setNonceCode(nonce);
                        setIsAuthenticated(true);
                        const decode: JwtPayloadData = jwtDecode(token);
                        setUser({id: decode.data.user_id, displayName: decode.data.display_name});
                        if (decode.message) {
                            setErrorMessage(decode.message);
                        }
                        if (decode.data.user_id) {
                            dispatch(getUserOffers(decode.data.user_id));
                            dispatch({
                                type: setUserToStoreTypes.success,
                                result: {data: {user_id: decode.data.user_id, displayName: decode.data.display_name}}
                            });
                        }
                    } else {
                        setIsAuthenticated(false);
                    }

                    return res;
                })
                .catch((err) => {
                    console.warn("err on reset", err);
                    const message = err?.responseError?.message || "Unknown error occurred";
                    if (message) {
                        setErrorMessage(message);
                    }

                    setIsAuthenticated(false);
                });
        };
        validateToken();
    }, [dispatch]);

    return {isAuthenticated, user, isLoading, setIsAuthenticated, errMessage};
};

export default useAuthorisation;
