import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme} from "@emotion/react";
import {appLink, appPath} from "@rw/cars_routes";
import {carsTheme} from "@rw/themes";
import {jwtDecode} from "jwt-decode";
import {Dispatch} from "redux";

import {Spinner} from "../../app/components/Spinner";
import {apiLink} from "../../app/routes/api_link";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {DashboardWrapper} from "../DashboardWrapper";
import useAuthorisation from "../hooks/useAuthorise";
import {EditCar} from "./EditCar";

export const setDashboardUserOffersTypes = createRequestActionTypes({name: "setUserOffers", type: "GET", view: "DashboardEdit"});

const getUserOffers = (authorId: number) => async (dispatch: Dispatch) => {
    const url = apiLink.userOffers({})({author_id: authorId});

    return postRequest({}, url, {author_id: authorId})
        .then((res: any) => {
            dispatch({type: setDashboardUserOffersTypes.success, result: res});

            return res;
        })
        .catch((err) => {
            console.warn("err on reset", err);
        });
};

interface JwtPayloadData {
    data: {
        user_id: number | null;
        display_name: string | null;
    };
}

type DashViewType = "addOffer" | "showOffers";

export const EditDynamic = () => {
    const [nonceCode, setNonceCode] = useState<string | null>(null);
    const {isAuthenticated, user, setIsAuthenticated, errMessage} = useAuthorisation();

    const handleDashView = () => {
        window.location.href = appLink.admin.userDashboard;
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        window.location.href = appPath.admin.login;
    };

    if (isAuthenticated === null) {
        return (
            <DashboardWrapper>
                <div css={containerStyle}>
                    <span>Authoryzacja... </span>
                    <Spinner />
                </div>
            </DashboardWrapper>
        );
    }

    if (!isAuthenticated) {
        setTimeout(() => {
            window.location.href = appPath.admin.login;
        }, 3000);
        return (
            <DashboardWrapper>
                <div css={containerStyle}>{errMessage}</div>
            </DashboardWrapper>
        );
    }

    return (
        <DashboardWrapper>
            <p css={logoutStyle} onClick={logout}>
                {user.displayName ? user.displayName : null} Wyloguj
            </p>
            <div css={container}>
                <div css={aside}>
                    <ul css={asideNav}>
                        <li css={inactive} onClick={handleDashView}>
                            Wróć do panelu
                        </li>
                    </ul>
                </div>
                <div css={contentWrapper}>
                    <EditCar user={user} nonce={nonceCode} />
                </div>
            </div>
        </DashboardWrapper>
    );
};

const inactive = css`
    border-bottom: 2px solid #fff;
    color: #000;
`;
const asideNav = (theme: Theme) => css`
    li {
        list-style: none;
        margin-bottom: 6px;

        &:hover {
            cursor: pointer;
            color: ${theme.colors.brand_primary};
        }
    }
`;
const contentWrapper = css`
    width: 100%;
`;
const aside = css`
    width: 300px;
    height: 100%;
    padding: 0 4rem 0 0;
`;

const container = css`
    max-width: 1190px;
    margin: 1rem auto;
    display: flex;
`;
const logoutStyle = (theme: Theme) => css`
    &:hover {
        color: ${theme.colors.brand_primary};
    }
`;

const containerStyle = css`
    padding: 4rem;
`;
