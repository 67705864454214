import * as React from "react";
import {SyntheticEvent} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {forEach, map, times} from "lodash";
import {lighten} from "polished";

enum RequestState {
    None,
    Waiting,
    Success,
    Error
}

interface IProps {
    iconPrev?: JSX.Element;
    iconNext?: JSX.Element;
    requestState?: RequestState;
    onChangePageClick?: (page: number) => void;
    locationPathname?: string;
    hrefBuilder?: (page: number) => string;
    pageCount: number;
    currentPage: number;
    multiNumbersInside?: boolean;
    hideNumbersOnMobile?: boolean;
}
interface IState {
    currentPage: number;
}
export class PaginationWithList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentPage: this.props.currentPage
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.requestState === RequestState.Waiting && this.props.requestState === RequestState.Success) {
            return this.setState({currentPage: this.props.currentPage});
        } else if (prevProps.currentPage !== this.props.currentPage && prevProps.locationPathname === this.props.locationPathname) {
            return this.setState({currentPage: this.props.currentPage});
        }
    }

    private renderPrevButton = () => {
        const prevPageNumber = this.props.currentPage - 1 || 1;
        const prevPageLink = this.props.hrefBuilder && this.props.hrefBuilder(prevPageNumber);

        // const isDisabled = this.props.currentPage <= 1 || this.props.requestState !== RequestState.Success;
        const isDisabled = this.props.currentPage <= 1;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(prevPageNumber);
                e.preventDefault();
            }
        };

        return (
            <PrevButton onClick={onClick} href={prevPageLink} rel="prev" data-testid="prev-page-link" isDisabled={isDisabled}>
                {`<`}
            </PrevButton>
        );
    };

    private renderNextButton = () => {
        const nextPageNumber = this.props.currentPage + 1;
        const nextPageLink = this.props.hrefBuilder && this.props.hrefBuilder(nextPageNumber);

        // const isDisabled = this.props.currentPage >= this.props.pageCount || this.props.requestState !== RequestState.Success;
        const isDisabled = this.props.currentPage >= this.props.pageCount;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(nextPageNumber);
                e.preventDefault();
            }
        };

        return (
            <NextButton onClick={onClick} href={isDisabled ? "" : nextPageLink} rel="next" data-testid="next-page-link" isDisabled={isDisabled}>
                {`>`}
            </NextButton>
        );
    };

    private renderPageButton = (value: number, current: number) => {
        const url = this.props.hrefBuilder && this.props.hrefBuilder(value);
        const isCurrentPage = value === current;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(value);
                e.preventDefault();
            }
        };

        return (
            <li>
                <PaginationNumber onClick={onClick} href={url} isCurrentPage={isCurrentPage}>
                    <span>{value}</span>
                </PaginationNumber>
            </li>
        );
    };

    private renderPageSeparator = () => {
        return (
            <li>
                <span data-testid="pagination-separator">...</span>
            </li>
        );
    };

    private generatePages = (pages: number[], current: number) => {
        const itemsToShow = 3;

        const last = pages.length;
        let bottomRange = current - 4 >= 0 ? current - 4 : 0;

        let topRange = current < 5 ? 5 : current + 1;
        if (topRange + 2 >= last) {
            topRange = topRange + 2;
            bottomRange = last >= 7 ? last - 7 : 0;
        }
        const pagesRange = pages.slice(bottomRange, topRange);

        const pagesArr = [];
        pagesArr.push(this.renderPageButton(1, current));

        const hasBottomRangeSeparator = last > 7 && current > 4;
        const hasTopRangeSeparator = last > 7 && current + 3 < last;

        if (hasBottomRangeSeparator) {
            pagesArr.push(this.renderPageSeparator());
            forEach(pagesRange.slice(2), (page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        } else if (current + itemsToShow - 1 < last) {
            forEach(pagesRange.slice(1, topRange), (page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        } else {
            forEach(pagesRange.slice(1), (page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        }
        if (hasTopRangeSeparator) {
            pagesArr.push(this.renderPageSeparator());
            pagesArr.push(this.renderPageButton(last, current));
        }
        return map(pagesArr, (element: JSX.Element, index: number) => <React.Fragment key={index}>{element}</React.Fragment>);
    };

    private renderPageCount = (pageCount: number) => {
        if (this.props.multiNumbersInside) {
            return this.generatePages(
                times(pageCount, (p: number) => p + 1),
                this.state.currentPage
            );
        }
        return `${this.state.currentPage} z ${pageCount}`;
    };

    public render() {
        return this.props.pageCount > 1 ? (
            <PaginationHolder pageCount={this.props.pageCount} currentPage={this.state.currentPage}>
                {this.renderPrevButton()}
                <PaginationNumbersHolder hideNumbersOnMobile={this.props.hideNumbersOnMobile}>
                    {this.renderPageCount(this.props.pageCount)}
                </PaginationNumbersHolder>
                {this.renderNextButton()}
            </PaginationHolder>
        ) : null;
    }
}

interface IPaginationHolder {
    pageCount: number;
    currentPage: number;
}

interface IPaginationNumbersHolder {
    hideNumbersOnMobile?: boolean;
}

interface IPaginationNumber {
    isCurrentPage: boolean;
}

interface IPrevNextButton {
    isDisabled: boolean;
}

const prevNextButtonWidth = "50px";
const widthHeight = "35px";

const PaginationHolder = styled.nav<IPaginationHolder>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12px;

    @media (min-width: 1024px) {
        flex-wrap: nowrap;
    }

    @media (max-width: 375px) {
        ${(props) =>
            props.pageCount === 7 &&
            css`
                > ul > li {
                    margin-right: 4px !important;
                }
            `}
    }

    ${(props) =>
        props.pageCount > 8 &&
        props.currentPage >= 5 &&
        !(props.pageCount > 8 && props.pageCount - props.currentPage < 4) &&
        css`
            > a {
                width: ${prevNextButtonWidth};
            }
        `}
`;

const paginationButton = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${widthHeight};
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: #fff;
        text-decoration: none;
    }
`;

const prevNextButton = css`
    width: ${prevNextButtonWidth};
    padding: 0 16px;
    justify-content: space-between;
    cursor: pointer;
`;

const PrevButton = styled.a<IPrevNextButton>`
    ${paginationButton};
    ${prevNextButton};
    border: 1px solid transparent;
    color: #43444d;
    border-radius: 4px;
    margin-right: 5px;
    order: 2;
    display: flex;
    justify-content: center;

    > svg {
        margin-right: 10px;
        fill: #ff5a00;
    }

    @media (min-width: 1024px) {
        order: 1;
        margin-right: 10px;
    }

    &:hover,
    &:active {
        background-color: #ff5a00;
        color: #fff;
        border: 1px solid #ff5a00;
    }

    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

const NextButton = styled.a<IPrevNextButton>`
    ${paginationButton};
    ${prevNextButton};
    color: #43444d;
    border: 1px solid transparent;

    border-radius: 4px;
    margin-left: 5px;
    order: 3;
    display: flex;
    justify-content: center;

    > svg {
        margin-left: 10px;
        fill: #ff5a00;
    }

    @media (min-width: 1024px) {
        margin-left: 10px;
    }

    &:hover,
    &:active {
        background-color: #ff5a00;
        color: #fff;
        border: 1px solid #ff5a00;
    }

    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

const PaginationNumbersHolder = styled.ul<IPaginationNumbersHolder>`
    display: ${(props) => (props.hideNumbersOnMobile ? `none` : `flex`)};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    order: 1;
    flex-grow: 1;
    flex-basis: 100%;
    list-style: none;
    padding-left: 0;

    @media (min-width: 1024px) {
        display: flex;

        order: 2;
        flex-grow: 0;
        flex-basis: auto;
        margin: 5px 0;
    }

    > li {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`;

const PaginationNumber = styled.a<IPaginationNumber>`
    ${paginationButton};
    border: 1px solid #ff5a00;
    color: #43444d;
    border-radius: 4px;
    width: ${widthHeight};

    &:hover,
    &:active {
        background-color: #ff5a00;
    }

    ${(props) =>
        props.isCurrentPage &&
        css`
            background-color: #ff5a00;
            color: #fff;
            pointer-events: none;

            &:hover,
            &:active,
            &:focus {
                background-color: ${lighten(0.05, "#FF5A00")};
                color: #fff;
            }
        `};
`;
