import {Dispatch} from "redux";

import {IEditOfferParam} from "../../../../../../packages/utils/cars_routes";
import {apiLink} from "../../../app/routes/api_link";
import {fetchCarListTypes} from "../../../app/routes/data_fetcher/app_path_data_fetcher";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";

export interface mappedCarDetailResponse {
    id: number;
    title: string;
    content: string;
    make: string;
    model: string;
    year: string;
    millage: string;
    engine: string;
    engine_size: string;
    power: number;
    price: string;
    gearbox: string;
    color: string;
    voivodeship: string;
    city: string;
    registry_date: string;
    seller_name: string;
    phone_number: string;
    version: string;
    generation: string;
    doors_counts: number;
    seats_counts: number;
    vin_id: string;
    body_type: string;
    damaged: boolean;
    imported: boolean;
    multimedia: string[];
    comfort_and_convenience: string[];
    appearance_and_materials: string[];
    safety: string[];
    additional_features: string[];
    filekeys: string;
}

export interface ICarDetailResponse {
    data: {
        id: number;
        title: string;
        content: string;
        slug: string;
        acf: {
            make: string;
            model: string;
            year: string;
            millage: string;
            engine: string;
            price: string;
            gallery: any;
            gearbox: string;
            engine_size: string;
            horse_power: string;
            city: string;
            wojew: string;
            first_registation_date: string;
            sellers_name: string;
            phone_number: string;
            body_type: string;
            damaged: string[]; //'tak/nie'
            imported: string[];
            filekeys?: string;
        };
        details_basic: {
            version: string;
            generation: string;
            doors_counts: string;
            spots_counts: string;
            car_color: string;
            vin: string;
        };
        multimedia_options: {
            multimedia: string[];
            comfort_and_convenience: string[];
            appearance_and_materials: string[];
            safety: string[];
            additional_features: string[];
        };

        data_back?: any;
    };
}
export const fetchCarDetailsToEditTypes = createRequestActionTypes({view: "Offer", type: "GET", name: "CarDetail"});

//   author_id: props.user.id || 1,
//                 title: values.title,
//                 content: values.content,
//                 expiry: values.expiry,
//                 make: values.make,
//                 model: values.model,
//                 year: values.year,
//                 millage: values.millage,
//                 engine: values.engine,
//                 engine_size: values.engine_size,
//                 power: values.power,
//                 price: values.price,
//                 gearbox: values.gearbox,
//                 color: values.color,
//                 voivodeship: values.voivodeship,
//                 city: values.city,
//                 gallery: galleryData,
//                 mainImage: galleryData[0]

export const fetchCarDetailsToEditAtRoute =
    (services: Partial<IServices>, route: IRouteState<IEditOfferParam>) =>
    (dispatch: Dispatch): Promise<ICarDetailResponse | boolean> => {
        dispatch({type: fetchCarDetailsToEditTypes.start, latestQuery: route.params});

        //const url = apiLink.cars.detail.base({})({carId: route.params.carId});
        const url = appendQueryString(apiLink.cars.detail.base({})({carId: route.params.offerId}), {ss: "s1b"});
        return getRequest(services, url, "fetchCarDetailsToEditAtRoute")
            .then(async (json: ICarDetailResponse) => {
                //  console.log("json", json.data);
                const mappedResult = {
                    id: json.data.id,
                    //   author_id: props.user.id || 1, tego nam brakuje w odpowiedzi
                    title: json.data.title,
                    content: json.data.content,
                    // expiry: values.expiry, // tego raczej nie potrzebujemy
                    make: json.data.acf.make,
                    model: json.data.acf.model,
                    year: json.data.acf.year,
                    millage: json.data.acf.millage,
                    engine: json.data.acf.engine,
                    engine_size: json.data.acf.engine_size,
                    power: Number(json.data.acf.horse_power),
                    price: json.data.acf.price,
                    gearbox: json.data.acf.gearbox,
                    color: json.data.details_basic.car_color,
                    voivodeship: json.data.acf.wojew,
                    city: json.data.acf.city,
                    registry_date: json.data.acf.first_registation_date,
                    seller_name: json.data.acf.sellers_name,
                    phone_number: json.data.acf.phone_number,
                    version: json.data.details_basic.version,
                    generation: json.data.details_basic.generation,
                    doors_counts: Number(json.data.details_basic.doors_counts),
                    seats_counts: Number(json.data.details_basic.spots_counts),
                    vin_id: json.data.details_basic.vin,
                    body_type: json.data.acf.body_type,
                    damaged: json.data.acf.damaged[0] === "tak" ? true : false,
                    imported: json.data.acf.imported[0] === "tak" ? true : false,
                    multimedia: json.data.multimedia_options.multimedia,
                    comfort_and_convenience: json.data.multimedia_options.comfort_and_convenience,
                    appearance_and_materials: json.data.multimedia_options.appearance_and_materials,
                    safety: json.data.multimedia_options.safety,
                    additional_features: json.data.multimedia_options.additional_features,
                    filekeys: json.data.acf.filekeys
                    // mainImage: galleryData[0]
                };

                dispatch({type: fetchCarDetailsToEditTypes.success, result: mappedResult});

                return json;
            })
            .catch(async (err) => {
                //   console.log("FETCH DETAIL err", err);
                //await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            });
    };
